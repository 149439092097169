<!-- Template -->
<template>
  <div class="a-request-modal" v-show="modal.isActive">
    <div class="a-request-modal__wrapper">
      <button class="a-request-modal__wrapper__close" type="button" @click="handleClick">
        <IconCancelCircle />
      </button>

      <h1 v-if="modal.title" class="a-request-modal__wrapper__title">
        {{ modal.title }}
      </h1>

      <div
        v-html="modal.content"
        class="a-request-modal__wrapper__content"
        :class="`a-request-modal__wrapper__content--align-${modal.align ? modal.align : 'center'}`"
      />

      <div class="a-request-modal__wrapper__actions">
        <button
          v-for="(actionItem, index) in modal.actionsList"
          :key="`${index}-${actionItem.label}`"
          class="a-request-modal__wrapper__actions__button"
          :class="{ 'a-request-modal__wrapper__actions__button--alternative': actionItem.isAlternative }"
          type="button"
          @click="$emit(actionItem.emit)"
        >{{ actionItem.label }}</button>
      </div>
    </div>
  </div>
</template>

<!-- Scripts -->
<script>
// Icones externos
import IconCancelCircle from '~/assets/icons/IconCancelCircle.vue'

// Componente
export default {
  // Nome do componente
  name: "AtomRequestModal",
  // Componentes importados
  components: {
    // Icones
    IconCancelCircle ,
  },
  // Layout padrão da página
  layout: "",
  // Validacao de pagina
  validate () {

  },
  // Middleware
  middleware ({}) {

  },
  // Mixins
  mixins: [

  ],
  // Propriedades herdadas
  props: {
    modal: { type: Object, required: true },
  },
  // Propriedades
  data () {
    return {}
  },
  // Dados computados
  computed: {

  },
  // Obsevadores
  watch: {
  },
  // Ciclo de vida
  beforeCreate() {

  },
  // Metodos
  methods: {
    handleClick() {
      this.$emit('modal:toggle', false);
    }
  },
}
</script>

<!-- Estilos -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.a-request-modal {
  z-index: 997;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100%;

  @media screen and (max-width: 600px) {
    place-items: end center;
  }

  &::before {
    z-index: 998;
    position: absolute;
    content: '';
    display: grid;
    width: 100%;
    height: 100vh;
    background-color: $alfa-brand-primary;
    filter: opacity(0.25);
  }

  &__wrapper {
    z-index: 999;
    position: relative;
    display: grid;
    place-content: start stretch;
    row-gap: 32px;
    width: clamp(334px, 100%, 442px);
    padding: 56px 56px 40px;
    border-radius: 5px;
    background-color: $alfa-support-1;
    @include box-shadow(#00468233, 0, 3px, 4px);

    @media screen and (max-width: 600px) {
      border-radius: 24px 24px 0 0;
      width: 100%;
      padding: 40px;
      @include box-shadow(#000000, 0, 32px, 64px);
    }

    &__close {
      cursor: pointer;
      position: absolute;
      top: 16px;
      right: 16px;
      display: grid;
      place-content: center;
      width: 25px;
      height: 25px;
      background-color: unset;
      transition: transform 100ms linear;

      &:active {
        transform: scale(.85);
      }
    }

    &__title {
      @include font-title-modal(center, 'Montserrat Bold');
    }

    &__content {
      display: grid;
      place-items: start stretch;
      row-gap: 16px;

      &--align-left {
        @include font-body(left);
      }

      &--align-center {
        @include font-body(center);
      }

      &--align-right {
        @include font-body(right);
      }

      &--align-justify {
        @include font-body(justify);
      }

      :deep(p) {
        u {
          @include font-body-big-text(left);
        }

        small {
          @include font-body-big-text(left);
        }
      }

      :deep(h3) {
        @include font-title-modal(center);

        & ~ p {
          &:not(:last-of-type) {
            @include font-body(left, 'Montserrat');

            strong {
              @include font-body(left, 'Montserrat SemiBold');
            }
          }

          &:first-of-type {
            margin-top: 16px;
          }

          &:last-of-type {
            @include font-body(left, 'Montserrat SemiBold');
          }
        }

        & ~ ul {
          display: grid;
          place-items: start stretch;
          row-gap: 32px;

          li {
            display: grid;
            grid-auto-flow: column;
            place-content: stretch start;
            place-items: center;
            column-gap: 16px;

            div {
              display: grid;
              place-content: center;
              width: 48px;
              height: 48px;
              border-radius: 50%;
              background-color: $alfa-support-12;
              @include font-simulation-highlight(center, 'Montserrat SemiBold', $alfa-support-1);
              @include box-shadow();
            }

            span {
              @include font-body-big-text(left);

              strong {
                @include font-body-big-text(left, 'Montserrat SemiBold');
              }

              a {
                cursor: pointer;
                text-decoration: underline;

                &:link,
                &:visited,
                &:hover,
                &:active {
                  color: $alfa-brand-primary;
                }
              }
            }
          }
        }
      }
    }

    &__actions {
      display: grid;
      grid-auto-flow: column;
      place-content: center;
      column-gap: 24px;
      margin-top: 8px;

      &__button {
        &:not(&--alternative) {
          @include button-extra-small(1);
        }

        &--alternative {
          @include button-extra-small(2);
        }
      }
    }
  }
}
</style>
